<template>
  <StageTemplate
    title="Wir sind FONIC - das ist die Wahrheit"
    class="svg"
    :color="color"
    :stage-visual="stageVisual"
    :stage-visual-mobile="stageVisualMobile"
    :href="$router.resolve(path).href"
    :animate-elements="animateElements"
    :svg-view-box-height="600"
  >
  </StageTemplate>
</template>

<script>
// Import the used SVG otherwise it will not land in the prod build!
import StageTemplate from 'src/components/common/stages/templates/background_title_speechbubble_svg.vue'
import StageVisual from './resources/autumn/stagevisual.svg'
import StageVisualMobile from './resources/autumn/stagevisual-mobile.svg'

export default {
  name: 'FonicAutumn',
  components: {
    StageTemplate
  },
  data() {
    return {
      stageVisual: StageVisual,
      stageVisualMobile: StageVisualMobile,
      color: '#ffc13c',
      path: {
        name: 'PortalTariffOverview'
      },
      animateElements: [
        {
          query: '#regenschirm',
          animation: 'floating-1 10s linear 0s infinite normal',
          calculateElementCenterPoint: true
        },
        {
          query: '#ufo-eule',
          animation:
            'wobble-left-right ${random(10,15)}s ease-in 0s infinite reverse forwards',
          calculateElementCenterPoint: true
        },

        {
          query: '#lama-hut',
          animation: 'bounce-from-top 2s ease 0s 1 normal none'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.image__wrapper.image_component {
  img {
    .ek {
      display: none;
    }
  }
}
</style>

// These animations need to be globally accessible, otherwise the f-inline-svg
component two layers further down will not be able to // access them
<style lang="scss">
@import 'src/components/common/stages/keyframes';
</style>

<style lang="scss" scoped>
.svg {
  background: var(--autumn);

  @include breakpoint($from-desktop) {
    :deep(.button) {
      margin-bottom: 40px;
    }
  }
}
</style>
